import React from 'react';

import Button from '@mui/material/Button';

import useAccount from 'hooks/useAccount';
import useUser from 'hooks/useUser';

import StyledIconButton from 'components/buttons/StyledIconButton';

import SidebarOptions from './SidebarOptions';
import {
  AccountLogo,
  AppName,
  CompanyName,
  FooterContainer,
  HeaderContainer,
  SidebarContainer,
  StyledDivider,
} from './Template.styles';

const Sidebar = () => {
  const { accountLogoUrl } = useAccount();
  const { userDetails, logout } = useUser();

  const welcomeTxt = React.useMemo(
    () => (
      <>
        <p>Welcome back,</p>
        <p>{`${userDetails.firstName ?? ''} ${userDetails.lastName ?? ''}`}</p>
      </>
    ),
    [userDetails],
  );

  return (
    <SidebarContainer>
      <HeaderContainer>{welcomeTxt}</HeaderContainer>
      <SidebarOptions />
      <FooterContainer>
        {accountLogoUrl && (
          <AccountLogo>
            <img src={accountLogoUrl} alt="Account Logo" />
          </AccountLogo>
        )}
        <StyledDivider />
        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          sx={{
            width: 'calc(100% - 4rem)',
            display: { xs: 'none', sm: 'block' },
          }}
          onClick={logout}
        >
          Log Out
        </Button>
        <StyledIconButton
          name="exit"
          color="white"
          sx={{ display: { xs: 'flex', sm: 'none' } }}
          onClick={logout}
        />
        <AppName>
          Disease State
          <br /> Management Portal
        </AppName>
        <CompanyName>By Impact Health</CompanyName>
      </FooterContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
