import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import NativeSelect from '@mui/material/NativeSelect';

import { countryToFlag, countries } from 'utils/countries';

import Input from 'components/forms/input';
import { Label } from 'utils/styles';

const CountrySelector = ({ value, handleChange, disabled }) => (
  <InputAdornment position="start">
    <Label htmlFor="country" visible={false}>
      Country
    </Label>
    <NativeSelect
      value={value}
      inputProps={{
        name: 'country',
        id: 'country',
      }}
      onChange={handleChange}
      disabled={disabled}
      sx={{ width: 'max-content' }}
    >
      <option value=""></option>
      {countries.map(({ phone, code }, index) => (
        <option value={code} key={index}>
          {countryToFlag(code)} ({phone})
        </option>
      ))}
    </NativeSelect>
  </InputAdornment>
);

CountrySelector.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const InputPhoneNumber = ({
  value = '',
  name,
  onChange,
  disabled,
  ...remainingProps
}) => {
  const selectedCountry = countries.find(({ phone }) =>
    value.startsWith(phone),
  ) ?? { code: '' };
  const initialValue =
    value && selectedCountry.code !== ''
      ? value.substring(selectedCountry.phone.length)
      : value;

  const [phoneNumber, setPhoneNumber] = useState(initialValue);
  const [countryCode, setCountryCode] = useState(selectedCountry.code);

  // Set formik value with the selected phone number code
  useEffect(() => {
    let phoneNumberCode = '';
    if (countryCode) {
      phoneNumberCode = countries.find(
        ({ code }) => code === countryCode,
      ).phone;
    }
    if (!disabled) {
      onChange({
        target: {
          value: phoneNumber ? `${phoneNumberCode}${phoneNumber}` : '',
          name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, countryCode]);

  // Reset phoneNumber when formik resets the value
  useEffect(() => {
    if (value === '' && phoneNumber !== '') {
      setPhoneNumber('');
    }
    if (value !== '' && phoneNumber === '') {
      const newSelectedCountry = countries.find(({ phone }) =>
        value.startsWith(phone),
      );
      setCountryCode(newSelectedCountry.code);
      setPhoneNumber(value.substring(newSelectedCountry.phone.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleInputChange = (ev) => {
    setPhoneNumber(ev.target.value);
  };

  const handleCountryChange = (ev) => {
    setCountryCode(ev.target.value);
  };

  return (
    <Input
      {...remainingProps}
      disabled={disabled}
      id={`${name}WithCountrySelector`}
      name={`${name}WithCountrySelector`}
      value={phoneNumber}
      onChange={handleInputChange}
      startAdornment={
        <CountrySelector
          value={countryCode}
          handleChange={handleCountryChange}
          disabled={disabled}
        />
      }
    />
  );
};

InputPhoneNumber.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default InputPhoneNumber;
