import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { Feature, Map, View } from 'ol';
import Projection from 'ol/proj/Projection';
import Polygon from 'ol/geom/Polygon';
import Control from 'ol/control/Control';
import ImageLayer from 'ol/layer/Image';
import { Vector as VectorLayer } from 'ol/layer';
import Static from 'ol/source/ImageStatic';
import { Vector as VectorSource } from 'ol/source';
import {
  DragPan,
  MouseWheelZoom,
  PinchZoom,
  Draw,
  Modify,
  Snap,
} from 'ol/interaction';
import 'ol/ol.css';

import { useTheme } from 'context/theme';

import './WoundImageDisplay.css';

import Controls from './Controls';
import {
  initialStyle,
  hideStyle,
  selectedStyle,
  vectorStyle,
} from './Controls.styles';

export default function WoundImageDisplay({ wound, setCurrentContours }) {
  const { palette } = useTheme();

  const woundImage = wound ? wound.imageUrl : null;

  const [center, setCenter] = React.useState([
    parseInt(wound.imageWidth) / 2,
    parseInt(wound.imageHeight) / 2,
  ]);
  const [showTracings, setShowTracings] = React.useState(true);
  const [zoom, setZoom] = React.useState(1);
  const [selections, setSelections] = React.useState([]);
  const [currentSelection, setCurrentSelection] = React.useState(null);
  const [selectionMode, setSelectionMode] = React.useState(false);
  const [mapDraggable, setMapDraggable] = React.useState(false);
  const [modifyActive, setModifyActive] = React.useState(false);
  const [lastTouchY, setLastTouchY] = React.useState(null);

  const [vectorSource, setVectorSource] = React.useState(null);
  const [map, setMap] = React.useState(null);
  const [dragPan, setDragPan] = React.useState(null);
  const [selectableArea, setSelectableArea] = React.useState(null);
  const [woundAreaSelection, setWoundAreaSelection] = React.useState(null);
  const [woundAreaSelectionSnap, setWoundAreaSelectionSnap] =
    React.useState(null);

  const highlightSelection = React.useCallback(
    (selections, id, hide) => {
      selections.forEach((selection) =>
        selection.setStyle(
          (hide && hideStyle) ||
            (selection.getId() === id
              ? () => selectedStyle(palette)
              : () => vectorStyle(palette)),
        ),
      );
    },
    [palette],
  );

  const handleSelectionAdd = React.useCallback(() => {
    map.addInteraction(woundAreaSelection);
    setSelectionMode(true);
    setCurrentSelection(null);
    highlightSelection(vectorSource.getFeatures(), null);
  }, [highlightSelection, map, vectorSource, woundAreaSelection]);

  const handleSelectionClear = React.useCallback(() => {
    woundAreaSelection.abortDrawing();
    map.removeInteraction(woundAreaSelection);

    if (currentSelection && !selectionMode) {
      vectorSource.removeFeature(vectorSource.getFeatureById(currentSelection));

      const features = vectorSource.getFeatures() ?? [];
      const currentId = features[0]?.getId() ?? null;

      setSelections(features);
      setCurrentSelection(currentId);
      setSelectionMode(false);

      highlightSelection(features, currentId);
      return;
    }
    setSelectionMode(false);
  }, [
    currentSelection,
    highlightSelection,
    map,
    selectionMode,
    vectorSource,
    woundAreaSelection,
  ]);

  const handleSelectionChoose = React.useCallback(
    (feature) => {
      setCurrentSelection(feature?.getId());
      highlightSelection(selections, feature?.getId());
    },
    [highlightSelection, selections],
  );

  const handleShowTracings = React.useCallback(
    (show) => {
      setShowTracings(show);
      highlightSelection(selections, currentSelection, !show);
    },
    [highlightSelection, selections, currentSelection],
  );

  const handleScrollOnMapTouch = (e) => {
    if (!mapDraggable && !modifyActive) {
      e.preventDefault();
      const scrollerBlades =
        document.scrollingElement || document.documentElement;
      const touches = e.touches || e.changedTouches;

      if (touches.length === 1) {
        if (lastTouchY !== null) {
          const by = lastTouchY - touches[0].clientY;
          scrollerBlades.scrollTop += by;
        }
        setLastTouchY(touches[0].clientY);
      }
    }
  };

  // Toggle drag
  React.useEffect(() => {
    if (map) {
      const button = document.getElementById('dragControlButton');
      if (mapDraggable) {
        button?.classList.add('active');
        map.getInteractions().insertAt(0, dragPan);
      } else {
        button?.classList.remove('active');
        map.removeInteraction(dragPan);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDraggable]);

  // Initialize all the necessary OpenLayers objects
  React.useEffect(() => {
    const extent = [
      0,
      0,
      parseInt(wound.imageWidth),
      parseInt(wound.imageHeight),
    ];
    const projection = new Projection({
      code: 'static-image',
      extent: extent,
    });

    const initialVectorSource = new VectorSource();

    initialVectorSource.on('addfeature', (ev) => {
      updateSelections(ev.target.getFeatures());
    });
    initialVectorSource.on('changefeature', (ev) => {
      updateSelections(ev.target.getFeatures());
    });
    initialVectorSource.on('removefeature', (ev) => {
      updateSelections(ev.target.getFeatures());
    });

    const initialVector = new VectorLayer({
      source: initialVectorSource,
      style: vectorStyle(palette),
    });

    const imageLayer = new ImageLayer({
      source: new Static({
        url: woundImage,
        imageExtent: extent,
        projection: projection,
      }),
    });

    const initialMap = new Map({
      target: null,
      interactions: [],
      layers: [imageLayer, initialVector],
      view: new View({
        center: center,
        zoom: zoom,
        projection: projection,
        maxZoom: 8,
      }),
    });

    const initialDragPan = new DragPan();
    const mouseWheelZoom = new MouseWheelZoom();
    const pinchZoom = new PinchZoom();

    const initialSelectableArea = new Modify({ source: initialVectorSource });
    const initialWoundAreaSelection = new Draw({
      source: initialVectorSource,
      type: 'Polygon',
      style: () => initialStyle(palette),
    });
    const initialWoundAreaSelectionSnap = new Snap({
      source: initialVectorSource,
    });

    initialMap.setTarget('map');

    initialMap.on('moveend', () => {
      setCenter(initialMap.getView().getCenter());
      setZoom(initialMap.getView().getZoom());
    });

    initialMap.addInteraction(mouseWheelZoom);
    initialMap.addInteraction(pinchZoom);

    let dragControlButton = document.createElement('button');
    dragControlButton.id = 'dragControlButton';
    dragControlButton.innerHTML = '⇳'; // #U+21F3
    dragControlButton.onclick = () =>
      setMapDraggable((prevState) => !prevState);

    let dragControlElement = document.createElement('div');
    dragControlElement.className = 'enable-drag ol-unselectable ol-control';
    dragControlElement.appendChild(dragControlButton);

    const dragControl = new Control({
      element: dragControlElement,
    });

    initialMap.addControl(dragControl);

    initialMap.addInteraction(initialWoundAreaSelectionSnap);
    initialMap.addInteraction(initialSelectableArea);

    if (wound.selections) {
      wound.selections.forEach((selection, i) => {
        let feature = new Feature({
          geometry: new Polygon(selection),
          name: 'Wound selection_' + i,
        });

        feature.setId('selection_' + i);

        initialVectorSource.addFeature(feature);
      });

      setSelections(initialVectorSource.getFeatures());
    }

    initialWoundAreaSelection.on('drawend', (ev) => {
      let newId = 'selection_' + selections.length;

      let i = selections.length;
      while (initialVectorSource.getFeatureById(newId)) {
        newId = 'selection_' + i++;
      }

      ev.feature.set('name', 'Wound ' + newId);
      ev.feature.setId(newId);

      const features = initialVectorSource.getFeatures().length
        ? initialVectorSource.getFeatures()
        : [];
      features.push(ev.feature);

      setSelections(features);
      setCurrentSelection(newId);
      setSelectionMode(false);

      highlightSelection(features, newId);

      initialMap.removeInteraction(initialWoundAreaSelection);
    });

    initialMap.getTargetElement().ontouchmove = handleScrollOnMapTouch;
    initialMap.getTargetElement().ontouchend = () => {
      setLastTouchY(null);
    };

    initialSelectableArea.on('modifystart', () => {
      setModifyActive(true);
    });
    initialSelectableArea.on('modifyend', () => {
      setModifyActive(false);
    });

    initialMap.getView().setCenter(center);
    initialMap.getView().setZoom(zoom);

    setVectorSource(initialVectorSource);
    setMap(initialMap);
    setDragPan(initialDragPan);
    setSelectableArea(initialSelectableArea);
    setWoundAreaSelection(initialWoundAreaSelection);
    setWoundAreaSelectionSnap(initialWoundAreaSelectionSnap);

    return () => {
      initialMap.setTarget(null);
      initialMap.removeInteraction(selectableArea);
      initialMap.removeInteraction(woundAreaSelection);
      initialMap.removeInteraction(woundAreaSelectionSnap);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSelections = (features) => {
    setSelections(features);

    const selectionsData = features.map((feature) => ({
      id: feature.getId(),
      contour: feature.getGeometry().getCoordinates()[0],
    }));

    setCurrentContours(
      selectionsData.map(({ contour }) =>
        contour.map((coord) => ({ x: coord[0], y: coord[1] })),
      ),
    );
  };

  return (
    <>
      <Box className="wound-area--wound">
        <Box id="map" className="wound-area--wound-map"></Box>
      </Box>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Typography>Show tracings</Typography>
        <Switch
          checked={showTracings}
          onChange={() => handleShowTracings(!showTracings)}
        />
      </Box>
      {showTracings && (
        <>
          <p className="info">
            Please trace the wound(s). If multiple, start with the wound nearest
            to the top of the image.
          </p>
          <Controls
            imageId={wound.imageId}
            selections={selections}
            currentSelection={currentSelection}
            selectionMode={selectionMode}
            onSelectionClear={handleSelectionClear}
            onSelectionAdd={handleSelectionAdd}
            onSelectionChoose={handleSelectionChoose}
          />
        </>
      )}
    </>
  );
}

WoundImageDisplay.propTypes = {
  wound: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    imageId: PropTypes.string.isRequired,
    selections: PropTypes.array,
  }).isRequired,
  setCurrentContours: PropTypes.func.isRequired,
};
