import React, { useMemo, useState } from 'react';
import update from 'immutability-helper';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';
import {
  DiseaseEnum,
  PathwayAdherenceLabels,
  PathwayStatusLabels,
  pathwayStepsOptions,
  ProcessSteps,
} from 'utils/enums';
import { getFieldProps } from 'utils/forms';
import { StyledCard } from 'utils/styles';

import FormWrapper from 'components/forms/form-wrapper';
import Select from 'components/forms/select';
import { Typography } from '@mui/material';

import { StyledValue } from './PathwayWidget.styles';

const PathwayWidget = () => {
  const [editing, setEditing] = useState(false);
  const { patientId } = useParams();
  const { user, updateUser } = useUserDetails(patientId);
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();

  const vlu = useMemo(
    () =>
      user?.patient?.diseases?.find(
        ({ disease }) => disease === DiseaseEnum.VLU,
      ),
    [user],
  );

  const [step, setStep] = React.useState(vlu?.diseasePathwayStep || null);

  const handleSubmit = async (data) => {
    clearFeedback();

    const submitData = update(user, {
      patient: {
        diseases: {
          $set: user.patient?.diseases.map((disease) => {
            if (disease?.disease === DiseaseEnum.VLU) {
              return update(disease, {
                diseasePathwayStep: { $set: data?.pathwayStep },
              });
            } else {
              return disease;
            }
          }),
        },
        practitioners: {
          $set: user?.patient?.practitioners.map(({ id }) => id),
        },
      },
    });

    try {
      await updateUser(patientId, submitData);
      setEditing(false);
      setSuccessFeedback('Updated with success');
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  return (
    <StyledCard>
      <FormWrapper
        initialValues={{ pathwayStep: vlu?.diseasePathwayStep || null }}
        onSubmit={handleSubmit}
        onEdit={() => setEditing(true)}
        onCancel={() => {
          setStep(vlu?.diseasePathwayStep);
          setEditing(false);
        }}
        disabled={!editing}
        enableReinitialize
        controlsOnTop
        title={`Venous Leg Ulceration - ${ProcessSteps[step]}`}
      >
        {({ formik }) => (
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Select
                label="Pathway Step:"
                options={pathwayStepsOptions}
                {...getFieldProps({
                  formik,
                  name: 'pathwayStep',
                  disabled: !editing,
                  changeOverride: (value) => setStep(value),
                })}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography mb={0.25}>Pathway Adherence:</Typography>
              <StyledValue
                id="pathwayAdherence"
                isVLU={vlu}
                color={vlu?.diseasePathwayAdherenceColor}
              >
                {vlu
                  ? PathwayAdherenceLabels[vlu.diseasePathwayAdherence]
                  : '-'}
              </StyledValue>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography mb={0.25}>Pathway Status:</Typography>
              <StyledValue isVLU={vlu} color={vlu?.diseaseStatusColor}>
                {vlu ? PathwayStatusLabels[vlu.diseaseStatus] : '-'}
              </StyledValue>
            </Grid>
          </Grid>
        )}
      </FormWrapper>
    </StyledCard>
  );
};

export default PathwayWidget;
