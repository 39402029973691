import styled from '@emotion/styled';

export const ContrastWrapper = styled('div')(({ theme, ratio }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '0.5rem',
  marginTop: '1rem',
  border: `2px solid ${theme.palette.grey.light}`,
  borderColor: ratio
    ? ratio > 7
      ? theme.palette.success.dark
      : theme.palette.error.main
    : '',
  padding: '0.5rem 0.875rem',
  borderRadius: '0.25rem',

  '.contrast-example': {
    width: 42,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey.light}`,
  },
}));
