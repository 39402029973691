import { createTheme } from '@mui/material/styles';

import { weight, family } from 'theme/fonts';

const defaultTypography = {
  fontSize: '1rem',
  fontFamily: family.openSans,
  fontWeight: weight.regular,
};

export const defaultColors = {
  primary: '#0F6076',
  primaryLight: '#c8f1f7',
  primaryDark: '#0B4251',
};

export default createTheme({
  palette: {
    primary: {
      main: defaultColors.primary,
      light: defaultColors.primaryLight,
      dark: defaultColors.primaryDark,
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: defaultColors.primary,
    },
    error: {
      main: '#e15240',
      light: '#eb8c80',
      dark: '#bb2f1d',
    },
    success: {
      main: '#a9eb80',
      dark: '#5abb1d',
    },
    warning: {
      main: '#FFC562',
    },
    base: {
      black: '#1d1d1d',
      white: '#ffffff',
    },
    grey: {
      extraLight: '#F9F9F9',
      light: '#D9D9D9',
      main: '#6E6E6E',
      dark: '#424242',
    },
    gradient: {
      main: `linear-gradient(45deg, ${defaultColors.primary}, ${defaultColors.primaryDark},${defaultColors.primaryLight})`,
    },
    wounds: {
      content: 'rgba(100, 255, 0, 0.1)',
      selected: 'rgba(100, 0, 255, 0.15)',
    },
    chartColorScale: ['#2e78b7', '#107B91', '#009FAE', '#3EA39E', '#82E9DE'],
    tonalOffset: 0.05,
  },
  typography: {
    button: defaultTypography,
    body1: defaultTypography,
    body2: defaultTypography,
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: { textTransform: 'none' },
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
