import styled from '@emotion/styled';
import { FormLabel } from '@mui/material';

export const StyledColorPicker = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledInputWrapper = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  '.input-picker': {
    position: 'absolute',
    left: 0,
    width: 0,
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
  },
}));

export const StyledLabel = styled(FormLabel)({
  marginBottom: '0.25rem',
  lineHeight: 'normal',
});

export const StyledTrigger = styled('label')(({ theme, value, disabled }) => ({
  height: '40px',
  aspectRatio: '1 / 1',
  cursor: 'pointer',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.grey.light}`,
  backgroundColor: value || '#000000',
  pointerEvents: disabled ? 'none' : 'auto',
}));
