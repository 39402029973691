import React, { useState } from 'react';

import Box from '@mui/material/Box';

import { formatDate } from 'utils/date';

import Toggle from 'components/toggle';

import {
  CardContent,
  CardHeader,
  Dates,
  Green,
  Info,
  Red,
  Table,
  Yellow,
} from './FollowUpSchedule.styles';

const PastPresentFuture = () => {
  const [scheduleOpen, setScheduleOpen] = useState(false);

  return (
    <>
      <CardHeader
        title="Venous Stent Follow-Up Schedule"
        action={
          <Toggle
            id="scheduleOpen"
            onChange={() => setScheduleOpen(!scheduleOpen)}
          />
        }
      />
      {scheduleOpen && (
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Info>
              <span>The 18 month follow-up window is currently open!</span>
              <span>
                Please complete the <a href="">Venous Stent Follow-up Forms</a>{' '}
                with the patient and the interventionist within this follow-up
                window
              </span>
            </Info>
            <Dates>
              <span>Venous Stent Date: 01/01/2021</span>
              <span>{`Today's Date: ${formatDate(
                new Date(),
                'MM/dd/yyyy',
              )}`}</span>
            </Dates>
          </Box>
          <Table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>10d</th>
                <th>30d</th>
                <th>3mo.</th>
                <th>6mo.</th>
                <th>12mo.</th>
                <th>18mo.</th>
                <th>24mo.</th>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>1/10/2021</span>
                    <span>-5d +5d</span>
                  </Box>
                </th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>1/30/2021</span>
                    <span>-7d +14d</span>
                  </Box>
                </th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>4/01/2021</span>
                    <span>+-30d</span>
                  </Box>
                </th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>7/01/2022</span>
                    <span>+-30d</span>
                  </Box>
                </th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>1/01/2022</span>
                    <span>+-30d</span>
                  </Box>
                </th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>7/01/2022</span>
                    <span>+-30d</span>
                  </Box>
                </th>
                <th>
                  <Box display="flex" flexDirection="column">
                    <span>1/01/2023</span>
                    <span>+-30d</span>
                  </Box>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ultrasound</td>
                <Green />
                <Green />
                <Green />
                <Red />
                <Green />
                <Yellow />
                <td></td>
              </tr>
              <tr>
                <td>Clinical Assessments</td>
                <Green />
                <Green />
                <Green />
                <Green />
                <Green />
                <Yellow />
                <td></td>
              </tr>
              <tr>
                <td>QoL Questionnaires</td>
                <Green />
                <Green />
                <Green />
                <Green />
                <Green />
                <Yellow />
                <td></td>
              </tr>
            </tbody>
          </Table>
        </CardContent>
      )}
    </>
  );
};

export default PastPresentFuture;
