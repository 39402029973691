import React, { useState } from 'react';
import update from 'immutability-helper';
import { useParams } from 'react-router-dom';

import useFeedback from 'hooks/useFeedback';
import useUserDetails from 'hooks/useUserDetails';
import { getErrorMessage } from 'utils/error';
import { Label } from 'utils/styles';

import FormWrapper from 'components/forms/form-wrapper';

import { NotesInput } from './PatientNotes.styles';

const PatientNotes = () => {
  const { patientId } = useParams();
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const { user, updateUser } = useUserDetails(patientId);
  const [editing, setEditing] = useState(false);

  const handleSave = async ({ notes }) => {
    const submitData = update(user, {
      patient: {
        notes: { $set: notes },
        practitioners: {
          $set: user?.patient?.practitioners?.map(({ id }) => id),
        },
      },
    });
    clearFeedback();
    try {
      await updateUser(patientId, submitData);
      setEditing(false);
      setSuccessFeedback('Patient notes updated with success');
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  };

  return (
    <FormWrapper
      initialValues={{ notes: user?.patient?.notes || '' }}
      onSubmit={handleSave}
      onEdit={() => setEditing(true)}
      onCancel={() => setEditing(false)}
      disabled={!editing}
      enableReinitialize
      controlsOnTop
      title="Patient Notes"
    >
      {({ formik }) => (
        <>
          <Label htmlFor="notes" visible={false}>
            Notes
          </Label>
          <NotesInput
            id="notes"
            name="notes"
            value={formik.values.notes ?? ''}
            disabled={!editing}
            fullWidth
            multiline
            rows={editing ? 9 : 10}
            onChange={formik.handleChange}
          />
        </>
      )}
    </FormWrapper>
  );
};

export default PatientNotes;
