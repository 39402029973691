import React, { useState } from 'react';
import * as Yup from 'yup';

import { RestartAlt } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import api from 'core/api';
import useAccount from 'hooks/useAccount';
import useFeedback from 'hooks/useFeedback';
import { AssetTypeEnum } from 'utils/enums';
import { getErrorDetails, getErrorMessage } from 'utils/error';
import { getFieldProps } from 'utils/forms';
import { FormSectionTitle } from 'utils/styles';
import { defaultColors } from 'theme/muiTheme';

import Template from 'pages/system-administrator/template';
import FileUpload from 'components/forms/file-upload';
import FormWrapper from 'components/forms/form-wrapper';
import Loader from 'components/loader';
import ColorPicker from 'components/forms/color-picker';

import ContrastChecker from 'components/contrast-checker';

import { SubTitle } from '../../../pages/account/AppAuth.styles';

const validationSchema = Yup.object({
  assetId: Yup.string().nullable(),
  primaryColor: Yup.string().nullable(),
  primaryDarkColor: Yup.string().nullable(),
  primaryLightColor: Yup.string().nullable(),
});

const System = () => {
  const { accountDetails, accountDetailsLoading } = useAccount();
  const { setErrorFeedback, setSuccessFeedback, clearFeedback } = useFeedback();
  const [disabled, setDisabled] = useState(true);

  const resetColors = (form) => {
    form.setValues({
      ...form.values,
      primaryColor: defaultColors.primary,
      primaryLightColor: defaultColors.primaryLight,
      primaryDarkColor: defaultColors.primaryDark,
    });
  };

  const handleSubmit = (values) => {
    clearFeedback();
    api
      .updateAccount(values)
      .then(() => {
        setSuccessFeedback('Updated with success');
        setDisabled(true);
      })
      .catch((error) => {
        setErrorFeedback(getErrorMessage(error), getErrorDetails(error));
      });
  };

  if (accountDetailsLoading) return <Loader />;

  return (
    <Template
      title="System Settings"
      actions={
        disabled && (
          <Button
            variant="contained"
            onClick={() => setDisabled(false)}
            data-test="edit-form-button"
          >
            Edit
          </Button>
        )
      }
    >
      <FormWrapper
        initialValues={accountDetails}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        disabled={disabled}
        onCancel={() => {
          setDisabled(true);
        }}
        enableReinitialize
      >
        {({ formik }) => (
          <form onSubmit={formik.handleSubmit}>
            <FormSectionTitle>Account Logo</FormSectionTitle>
            <SubTitle style={{ marginBottom: '0.5rem' }}>
              The file size for uploading a new logo should not exceed 1MB.
            </SubTitle>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12}>
                <FileUpload
                  label="Account Logo Upload"
                  type={AssetTypeEnum.ACCOUNT}
                  disabled={disabled}
                  maxSize={1}
                  {...getFieldProps({
                    formik,
                    name: 'assetId',
                    disabled: disabled,
                  })}
                />
              </Grid>
            </Grid>
            <FormSectionTitle>Look and Feel</FormSectionTitle>
            <SubTitle style={{ marginBottom: '0.5rem' }}>
              To ensure adequate accessibility to all users, the contrast for
              for all colors should be above <b>7</b>.
            </SubTitle>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={4}>
                <ColorPicker
                  label="Primary Color"
                  placeholder="#000000"
                  name="primaryColor"
                  value={formik.values.primaryColor}
                  onChange={formik.handleChange}
                  disabled={disabled}
                />
                {!disabled && (
                  <ContrastChecker color={formik.values.primaryColor} />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <ColorPicker
                  label="Primary Light Color"
                  placeholder="#000000"
                  name="primaryLightColor"
                  value={formik.values.primaryLightColor}
                  onChange={formik.handleChange}
                  disabled={disabled}
                />
                {!disabled && (
                  <ContrastChecker
                    color={formik.values.primaryLightColor}
                    comparisonColor="#1D1D1D"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <ColorPicker
                  label="Primary Dark Color"
                  placeholder="#000000"
                  name="primaryDarkColor"
                  value={formik.values.primaryDarkColor}
                  onChange={formik.handleChange}
                  disabled={disabled}
                />
                {!disabled && (
                  <ContrastChecker color={formik.values.primaryDarkColor} />
                )}
              </Grid>
            </Grid>

            {!disabled && (
              <Button
                variant="outlined"
                data-test="submit-form-button"
                startIcon={<RestartAlt />}
                disabled={disabled}
                onClick={() => resetColors(formik)}
              >
                Reset to Default Colors
              </Button>
            )}
          </form>
        )}
      </FormWrapper>
    </Template>
  );
};

export default System;
