import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Grid, Typography, Stack } from '@mui/material';

import { StyledCard } from 'utils/styles';
import { weight } from 'theme/fonts';

import SingleChoice from 'components/forms/single-choice';

const StyledContainer = styled(StyledCard)(({ theme, isBlue }) => ({
  backgroundColor: isBlue
    ? theme.palette.primary.main
    : theme.palette.base.white,
}));

const StyledLabel = styled(Typography)(({ theme, isBlue }) => ({
  textAlign: 'center',
  color: isBlue ? theme.palette.base.white : theme.palette.grey.dark,
}));

const StyledContent = styled(Typography)(({ theme, isBlue }) => ({
  textAlign: 'center',
  color: isBlue ? theme.palette.base.white : 'initial',
  fontSize: '1.25rem',
  fontWeight: weight.semibold,
}));

export const InfoCard = ({ label, content, isBlue = false }) => (
  <Grid item lg={3} md={6} xs={12}>
    <StyledContainer isBlue={isBlue}>
      <Stack spacing={1} alignItems="center">
        <StyledLabel isBlue={isBlue} component="span">
          {label}
        </StyledLabel>
        <StyledContent isBlue={isBlue} component="span">
          {content}
        </StyledContent>
      </Stack>
    </StyledContainer>
  </Grid>
);

InfoCard.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isBlue: PropTypes.bool,
};

export const SingleChoiceCard = ({ label, options, name }) => {
  const [value, setValue] = React.useState(options[0].value);

  return (
    <StyledCard>
      <SingleChoice
        name={name}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        label={label}
        options={options}
      />
    </StyledCard>
  );
};

SingleChoiceCard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
};
