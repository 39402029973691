import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useWidgetModal, { modalTypeEnum } from 'hooks/useWidgetModal';
import { CARE_TRACK_PATHS } from 'navigation/paths';
import { formatISOString, localWeekStartsOn1 } from 'utils/date';
import { PeriodEnum } from 'utils/enums';

import MonthlyDisplay from 'components/widgets/monthly-display';
import WeekPicker from 'components/widgets/week-picker';
import WeeklyDisplay from 'components/widgets/weekly-display';
import WidgetContainer from 'components/widgets/widget-container';
import WidgetTypeMenu from 'components/widgets/widget-type-menu';

import NewCompression from './NewCompression';
import { formFieldEnum } from './utils';

const listViewMode = [PeriodEnum.WEEK, PeriodEnum.MONTH];

const CompressionWidget = () => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState(listViewMode[0]);
  const [filters, setFilters] = useState(null);
  const { modal, handleAdd, handleClose } = useWidgetModal();
  const { data, doRequest: getCompressionList } = useRequest(
    api.getCompressionList,
  );
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleRefresh = useCallback(() => {
    if (!filters) return;
    getCompressionList({
      ...filters,
      DateTo: formatISOString(filters.DateTo),
      DateFrom: formatISOString(filters.DateFrom),
    });
  }, [filters, getCompressionList]);

  useEffect(handleRefresh, [handleRefresh]);

  const widgetData = useMemo(
    () =>
      data?.data?.map((item) => ({
        ...item,
        value: item[formFieldEnum.STOCKINGS],
      })) ?? [],
    [data],
  );

  const viewType = useMemo(() => {
    switch (viewMode) {
      case PeriodEnum.WEEK:
        return (
          <WeeklyDisplay
            data={widgetData}
            fetchData={setFilters}
            selectedDate={selectedDate}
          />
        );
      case PeriodEnum.MONTH:
        return <MonthlyDisplay data={widgetData} fetchData={setFilters} />;
      default:
        return null;
    }
  }, [viewMode, widgetData, selectedDate]);

  const renderModalContent = useMemo(() => {
    switch (modal?.type) {
      case modalTypeEnum.ADD:
        return (
          <NewCompression
            handleRefresh={handleRefresh}
            handleCancel={handleClose}
          />
        );
      default:
        return null;
    }
  }, [modal, handleRefresh, handleClose]);

  const renderPicker = useMemo(
    () =>
      viewMode === PeriodEnum.WEEK ? (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localWeekStartsOn1}
        >
          <WeekPicker
            name="compression-compliance-week-picker"
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </LocalizationProvider>
      ) : null,
    [viewMode, selectedDate],
  );

  return (
    <WidgetContainer
      title="Compression Compliance"
      onLogsClick={() => navigate(CARE_TRACK_PATHS.COMPRESSION_COMPLIANCE)}
      onAddClick={handleAdd}
      modalContent={renderModalContent}
      onModalClose={handleClose}
    >
      {viewType}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={8}>
          {renderPicker}
        </Grid>
        <Grid item xs={12} md={4}>
          <WidgetTypeMenu
            options={listViewMode}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default CompressionWidget;
