import styled from '@emotion/styled';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { weight } from 'theme/fonts';
import { breakpointsTheme } from 'theme/muiTheme';

export const PatientAvatar = styled(Avatar)(() => ({
  width: '4rem',
  height: '4rem',
  marginRight: '1rem',
}));

export const PatientInfolabel = styled('div')(({ theme }) => ({
  marginBottom: '0.25rem',

  '& span': {
    color: theme.palette.grey.dark,
  },

  '&:last-child': {
    marginBottom: 0,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: weight.bold,

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
  },

  '&.MuiButton-outlined': {
    border: '1px solid ' + theme.palette.grey.dark,
    color: theme.palette.grey.dark,
    fontWeight: weight.semibold,
  },
}));
