import styled from '@emotion/styled';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';

import { weight } from 'theme/fonts';
import { breakpointsTheme } from 'theme/muiTheme';

export const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
  border: '1px solid ' + theme.palette.grey.light,
  borderRadius: '0.25rem',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey.light,
}));

export const EncounterContainer = styled(ListItem)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '1rem',

  '&>div:first-of-type': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',

    '& p': {
      '&:first-of-type': {
        fontWeight: weight.semibold,
      },

      '&:last-child': {
        margin: 0,
        color: theme.palette.grey.main,
        fontSize: '0.75rem',
      },
    },
  },

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StyledPagination = styled(Pagination)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '0.5rem',
}));

export const StyledButton = styled(Button)(() => ({
  fontWeight: weight.bold,

  [breakpointsTheme.breakpoints.down('sm')]: {
    margin: '0.5rem 0 0 2rem',
  },
}));
