import styled from '@emotion/styled';

export const Wrapper = styled('main')(({ theme }) => ({
  minHeight: '100vh',
  padding: '2rem',
  backgroundColor: theme.palette.grey.extraLight,
}));

export const Section = styled('section')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
  boxShadow: `0 0.2rem 0.4rem ${theme.palette.grey.light}`,
}));

export const columnStyles = [
  { width: '25%' },
  { width: '30%' },
  { width: '30%' },
  { width: '15%' },
];
