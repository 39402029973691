import styled from '@emotion/styled';

import { weight } from 'theme/fonts';
import { breakpointsTheme } from 'theme/muiTheme';

export const Title = styled('span')(() => ({
  fontWeight: weight.semibold,
}));

export const Error = styled('p')(({ theme }) => ({
  fontWeight: weight.semibold,
  color: theme.palette.error.main,
}));

export const Section = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  padding: '2rem',
  backgroundColor: theme.palette.grey.extraLight,
}));

export const OutterWrapper = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
  boxShadow: `0 0.2rem 0.4rem ${theme.palette.grey.light}`,
}));

export const ContentContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const ImageContainer = styled('div')(() => ({
  width: '44rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const Img = styled('img')(({ theme }) => ({
  width: '100%',
  height: '69vh',
  backgroundColor: theme.palette.grey.extraLight,
  border: `1px solid ${theme.palette.grey.dark}`,
  borderRadius: '0.25rem',
  objectFit: 'contain',
}));

export const DetailsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginLeft: '2rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    margin: '2rem 0 0 0',
  },
}));

export const DetailsInfoContainer = styled('div')(() => ({
  display: 'flex',
  gap: '2rem',

  [breakpointsTheme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));
