import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import startOfMinute from 'date-fns/startOfMinute';

import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { INPUT_TYPES } from 'utils/constants';
import { getFieldProps } from 'utils/forms';

import Input from 'components/forms/input';

import {
  StyledBox,
  StyledContainer,
  StyledDateTimeSelect,
  StyledMinutes,
} from './AddTime.styles';

const AddTime = ({ formik }) => {
  const totalMinutes = useMemo(
    () =>
      formik.values.endedAt && formik.values.startedAt
        ? differenceInMinutes(
            new Date(formik.values.endedAt),
            new Date(formik.values.startedAt),
          )
        : '',
    [formik.values],
  );

  const setTime = useCallback(
    (fieldName) => formik.setFieldValue(fieldName, startOfMinute(new Date())),
    [formik],
  );

  return (
    <>
      <Input
        type={INPUT_TYPES.COMMENT}
        {...getFieldProps({ formik, name: 'text' })}
      />
      <StyledContainer>
        <StyledBox>
          <IconButton
            aria-label="set start time"
            onClick={() => setTime('startedAt')}
          >
            <AccessTimeIcon />
          </IconButton>
          <StyledDateTimeSelect
            label="Start Time"
            {...getFieldProps({ formik, name: 'startedAt' })}
          />
        </StyledBox>
        <StyledBox>
          <IconButton
            aria-label="set end time"
            onClick={() => setTime('endedAt')}
          >
            <AccessTimeIcon />
          </IconButton>
          <StyledDateTimeSelect
            label="Stop time"
            {...getFieldProps({ formik, name: 'endedAt' })}
          />
        </StyledBox>
        <StyledMinutes>
          <Input
            name="totalMinutes"
            label="Minutes"
            value={totalMinutes}
            disabled={true}
          />
        </StyledMinutes>
      </StyledContainer>
    </>
  );
};

AddTime.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    values: PropTypes.shape({
      startedAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      endedAt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      text: PropTypes.string,
    }),
  }),
};
export default AddTime;
