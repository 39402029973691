import styled from '@emotion/styled';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import { weight } from 'theme/fonts';

export const Label = styled(InputLabel)(({ theme, visible = true }) => ({
  width: 'fit-content',
  marginBottom: '0.25rem',
  color: theme.palette.base.black,
  lineHeight: 'normal',
  letterSpacing: 'normal',
  whiteSpace: 'normal',
  ...(visible
    ? {
        visibility: 'hidden',
        width: 0,
        height: 0,
        margin: 0,
      }
    : {}),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    position: 'initial',
    marginBottom: '0.25rem',
    color: theme.palette.base.black,
    transform: 'none !important',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    pointerEvents: 'initial',
  },
  '& legend': {
    maxWidth: 0,
  },
}));

export const ErrorText = styled('span')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'pre-wrap',
  marginTop: '0.25rem',
  lineHeight: 'normal',
  color: theme.palette.error.main,
}));

export const StyledCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '1.5rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.base.white,
  boxShadow: '0 0.1rem 0.25rem ' + theme.palette.grey.light,
}));

export const FormSectionTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '2.5rem',
  padding: '0 1.5rem',
  margin: '0 -1.5rem',
  backgroundColor: theme.palette.grey.light,
  fontSize: '1.25rem',
  fontWeight: weight.semibold,
}));

export const FormSubSectionTitle = styled('div')(({ theme }) => ({
  paddingBottom: '0.5rem',
  borderBottom: '1px solid ' + theme.palette.grey.light,
  fontWeight: weight.bold,
}));
