import React, { useMemo, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TimerIcon from '@mui/icons-material/Timer';

import useChat from 'hooks/useChat';
import useUserDetails from 'hooks/useUserDetails';
import { PATIENT_DASHBOARD_PATHS, PRACTITIONER_PATHS } from 'navigation/paths';

import Timetrack from './timetrack';

import {
  HeaderIconBadge,
  HeaderIconButton,
  TrackTimeIconButton,
} from './HeaderActions.styles';

const HeaderActions = () => {
  const { chatRooms, openChatWindow, closeChatWindow } = useChat();
  const { patientId } = useParams();
  const { user, isLoading } = useUserDetails(patientId);

  useEffect(() => {
    return () => {
      closeChatWindow();
    };
  }, [closeChatWindow]);

  /*
  TODO: we should have chatRoomId comming from backend on patient params
  Doing this action like this can cause error if the patient is also a clinic involved in a conversation
  */
  const userRoom = useMemo(() => {
    if (!chatRooms?.length || !user?.id) return null;
    return chatRooms.find(({ members }) =>
      members.find(({ userId }) => userId === user.id),
    );
  }, [chatRooms, user]);

  if (isLoading) return <CircularProgress />;
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ display: { xs: 'none', md: 'flex' } }}
    >
      <Timetrack />
      <TrackTimeIconButton
        aria-label="track time"
        component={Link}
        to={`${PRACTITIONER_PATHS.patients}/${patientId}/${PATIENT_DASHBOARD_PATHS.TIME_TRACK}`}
      >
        <TimerIcon />
      </TrackTimeIconButton>
      <HeaderIconBadge
        overlap="circular"
        badgeContent={userRoom?.unreadCount}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <HeaderIconButton
          disabled={!userRoom?.id}
          onClick={() => openChatWindow(userRoom?.id)}
        >
          <QuestionAnswerIcon />
        </HeaderIconButton>
      </HeaderIconBadge>
    </Box>
  );
};

export default HeaderActions;
