import styled from '@emotion/styled';

import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(({ theme, active }) => ({
  textTransform: 'none',

  '&.Mui-disabled': {
    backgroundColor: active === 'true' ? theme.palette.grey.main : undefined,
    color: active === 'true' ? theme.palette.base.white : undefined,
  },
}));
