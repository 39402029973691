import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const ChatInfoContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',

  [breakpointsTheme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

export const CategoryContainer = styled('div')(() => ({
  display: 'flex',
  gap: '1.5rem',
  marginTop: '1rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: 0,
  },
}));

export const Category = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    gap: '0.5rem',
  },

  '& span': {
    color: theme.palette.grey.dark,
  },
}));
