import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Box from '@mui/material/Box';

import HeaderTitle from 'components/titles/HeaderTitle';
import StyledIconButton from 'components/buttons/StyledIconButton';

import FormSubmit from '../form-submit';

const FormWrapper = ({
  initialValues,
  validationSchema,
  disabled,
  onSubmit,
  onCancel,
  onEdit,
  children,
  confirmStr = 'Save',
  cancelStr = 'Cancel',
  enableReinitialize,
  title,
  controlsOnTop,
  readOnly,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    onReset={onCancel}
    enableReinitialize={enableReinitialize}
  >
    {(formik) => (
      <>
        {(controlsOnTop || title) && !readOnly && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={!title ? 'flex-end' : 'space-between'}
            mb={2}
          >
            {title && <HeaderTitle title={title} type="h1" />}
            {controlsOnTop &&
              (!disabled ? (
                <FormSubmit
                  confirmStr={confirmStr}
                  cancelStr={cancelStr}
                  onConfirm={formik.dirty ? formik.handleSubmit : null}
                  onCancel={() => {
                    formik.handleReset();
                    if (onCancel) onCancel();
                  }}
                  noMargin
                />
              ) : (
                <StyledIconButton
                  name="edit"
                  color="turquoise"
                  onClick={onEdit}
                  data-test="edit-form-button"
                />
              ))}
          </Box>
        )}
        {children && children({ formik })}
        {!disabled && !controlsOnTop && (
          <FormSubmit
            confirmStr={confirmStr}
            cancelStr={cancelStr}
            onConfirm={formik.dirty ? formik.handleSubmit : null}
            onCancel={() => {
              formik.handleReset();
              if (onCancel) onCancel();
            }}
          />
        )}
      </>
    )}
  </Formik>
);

FormWrapper.propTypes = {
  initialValues: PropTypes.shape({}),
  validationSchema: PropTypes.shape({}),
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  confirmStr: PropTypes.string,
  cancelStr: PropTypes.string,
  children: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controlsOnTop: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FormWrapper;
