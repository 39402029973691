import styled from '@emotion/styled';

export const StyledMessagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignContent: 'flex-end',
  flexGrow: '1',
  overflowY: 'auto',
  paddingTop: '0.5rem',
  borderTop: `1px solid ${theme.palette.grey.light}`,
  borderBottom: `1px solid ${theme.palette.grey.light}`,
}));

export const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem',
};
