import styled from '@emotion/styled';

import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { weight, family } from 'theme/fonts';

export const StyledTableCell = styled(TableCell)((props) => ({
  '&.MuiTableCell-head': {
    height: '2.5rem',
    padding: '0 0 0 1.5rem',
    backgroundColor: props.theme.palette.primary.light,
    color: props.theme.palette.base.black,
  },

  '&.MuiTableCell-body': {
    height: '2.5rem',
    padding: '0 0 0 1.5rem',
    font: weight.regular + ' 1rem ' + family.openSans,
  },
}));

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey.extraLight,
  },
}));

export const StyledPagination = styled(Pagination)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1rem',
}));

export const StyledPaginationItem = styled(PaginationItem)(() => ({
  fontSize: 16,
}));

export const Section = styled('section')(() => ({
  position: 'relative',
}));

export const LoadingDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.base.black,
  opacity: '0.4',
  position: 'absolute',
}));

export const TableFeedback = styled(Alert)(() => ({
  marginBottom: '2rem',
}));
