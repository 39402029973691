import styled from '@emotion/styled';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { weight } from 'theme/fonts';
import { breakpointsTheme } from 'theme/muiTheme';

export const TemplateContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  backgroundColor: theme.palette.base.white,
}));

export const WrapperContainer = styled('main')(({ theme }) => ({
  width: 'calc(100% - 15rem)',
  marginLeft: '15rem',
  padding: '2rem 3%',
  background: theme.palette.grey.extraLight,

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: 'calc(100% - 3rem)',
    marginLeft: '3rem',
    padding: '1.5rem',
  },
}));

export const Header = styled('header')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',

  [breakpointsTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const SidebarContainer = styled('aside')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  overflowY: 'auto',
  width: '15rem',
  minWidth: '15rem',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem 0',
  backgroundColor: theme.palette.primary.main,
  boxShadow: '0 0.1rem 0.25rem ' + theme.palette.grey.light,

  [breakpointsTheme.breakpoints.down('sm')]: {
    width: '3rem',
    minWidth: '3rem',
    overflowX: 'hidden',
  },
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  padding: '0 2rem 2rem',

  '& p': {
    marginBottom: '0.5rem',
    color: theme.palette.base.white,
    fontWeight: weight.bold,

    [breakpointsTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const SidebarButton = styled(Button)(({ theme }) => ({
  minWidth: 'initial',
  justifyContent: 'start',
  marginBottom: '0.5rem',
  padding: '0.5rem 2rem',
  borderRadius: '0',
  color: theme.palette.base.white,
  textTransform: 'none',

  '&.MuiButton-containedSecondary': {
    fontWeight: weight.bold,
    backgroundColor: theme.palette.primary.dark,
  },

  '.MuiButton-startIcon': {
    marginRight: '1rem',

    '.MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },

    [breakpointsTheme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  [breakpointsTheme.breakpoints.down('sm')]: {
    height: '2rem',
    padding: '0.5rem 1.25rem',
    justifyContent: 'center',
  },
}));

export const SidebarButtonText = styled('span')(() => ({
  [breakpointsTheme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledBadge = styled(Badge)(() => ({
  '.MuiBadge-badge': {
    top: '0.85rem',
    right: '-2rem',
    fontSize: '1rem',
    padding: '0.5rem',
  },
}));

export const AccountLogo = styled('div')(({ theme }) => ({
  width: '96px',
  height: '96px',
  marginBottom: '2rem',
  overflow: 'hidden',
  background: 'red',
  borderRadius: '50%',
  backgroundColor: theme.palette.base.white,
  border: `2px solid ${theme.palette.primary.light}`,

  [breakpointsTheme.breakpoints.down('sm')]: {
    display: 'none',
  },

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  marginBottom: '2rem',
  borderColor: theme.palette.grey.extraLight,
}));

export const FooterContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'auto',

  [breakpointsTheme.breakpoints.down('sm')]: {
    padding: '0',
  },
}));

export const AppName = styled('p')(({ theme }) => ({
  marginTop: '2rem',
  color: theme.palette.base.white,
  textAlign: 'center',
  fontWeight: weight.bold,

  [breakpointsTheme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const CompanyName = styled('p')(({ theme }) => ({
  color: theme.palette.grey.extraLight,
  textAlign: 'center',
  fontSize: '0.75rem',
  fontWeight: weight.semibold,

  [breakpointsTheme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
