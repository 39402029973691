import React from 'react';
import PropTypes from 'prop-types';

import { INPUT_TYPES } from 'utils/constants';
import { StyledTextField } from 'utils/styles';

const getFieldProps = (props) => {
  switch (props.type) {
    case INPUT_TYPES.SHORT_TEXT:
      return {
        ...props,
        type: 'text',
        inputProps: {
          maxLength: 1024,
        },
      };
    case INPUT_TYPES.NUMERIC:
      return {
        ...props,
        type: 'number',
        inputProps: {
          min: props.min,
          max: props.max,
        },
      };
    case INPUT_TYPES.COMMENT:
      return {
        ...props,
        type: 'text',
        multiline: true,
        rows: 6,
        inputProps: {
          maxLength: 65536,
        },
      };
    case INPUT_TYPES.PASSWORD:
      return {
        ...props,
        type: 'password',
      };
  }
};

const Input = ({
  type = INPUT_TYPES.SHORT_TEXT,
  name,
  error,
  label,
  isRequired,
  startAdornment,
  endAdornment,
  ...remainingProps
}) => {
  return (
    <StyledTextField
      id={name}
      name={name}
      label={label || name}
      required={isRequired}
      error={!!error}
      helperText={error}
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true,
        htmlFor: name,
        style: {
          visibility: label ? 'visible' : 'hidden',
          height: label ? 'auto' : 0,
          marginBottom: label ? '0.25rem' : 0,
        },
      }}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
      {...getFieldProps({ type, ...remainingProps })}
      {...remainingProps}
    />
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.node,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
};

export default Input;
