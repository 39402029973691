import styled from '@emotion/styled';

import { breakpointsTheme } from 'theme/muiTheme';

export const StyledImage = styled('img')(() => ({
  maxWidth: '240px',
  maxHeight: '240px',
  marginTop: '0.5rem',
  borderRadius: '0.25rem',
  objectFit: 'contain',

  [breakpointsTheme.breakpoints.down('sm')]: {
    maxHeight: 'none',
    maxWidth: 'none',
    width: '100%',
  },
}));

export const LoadingDiv = styled('div')(() => ({
  width: '240px',
  aspectRatio: '16 / 9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '0.5rem',
}));
