import styled from '@emotion/styled';

import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';

export const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  marginRight: '0.5rem',
  border: '1px solid ' + theme.palette.grey.light,
  borderRadius: '0.25rem',
  resize: 'none',
  backgroundColor: theme.palette.grey.extraLight,
}));

export const ChatButton = styled(IconButton)(({ theme, disabled }) => ({
  fontSize: '1.5rem',
  color: disabled ? theme.palette.grey.light : theme.palette.primary.main,
}));

export const AttachedFile = styled(Chip)(({ theme }) => ({
  margin: '0 0 1rem 1rem',
  padding: '1rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.base.white,

  '&.MuiButtonBase-root .MuiChip-deleteIcon, &.MuiButtonBase-root .MuiChip-icon':
    {
      color: theme.palette.base.white,
    },
}));

export const AttachedHiddenField = styled('input')(() => ({
  display: 'none',
}));
