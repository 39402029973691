import styled from '@emotion/styled';

import { weight } from 'theme/fonts';

export const Name = styled('p')(({ isUnread }) => ({
  fontWeight: isUnread ? weight.bold : weight.semibold,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const Date = styled('p')(({ theme }) => ({
  marginLeft: '0.5rem',
  fontSize: '0.75rem',
  color: theme.palette.grey.dark,
}));

export const Message = styled('p')(({ isUnread }) => ({
  margin: 0,
  paddingBottom: '1rem',
  fontWeight: isUnread ? weight.semibold : weight.regular,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const WrapperContainer = styled('div')(
  ({ theme, isSelected, isUnread }) => ({
    position: 'relative',
    padding: '1rem 1rem 0 2.5rem',
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    cursor: 'pointer',
    backgroundColor: isSelected
      ? theme.palette.primary.light
      : theme.palette.base.white,

    '::before': isUnread
      ? {
          content: '""',
          position: 'absolute',
          top: '2.25rem',
          left: '0.75rem',
          width: '0.75rem',
          height: '0.75rem',
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
        }
      : {},
  }),
);

export const TabsContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const LoadingContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: '20',
  width: '100%;',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.grey.main,
}));
