import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Link } from '@mui/material';

import useFeedback from 'hooks/useFeedback';
import { getErrorMessage } from 'utils/error';

import { ContrastWrapper } from './ContrastChecker.styles';

const ContrastChecker = ({ color, comparisonColor = '#FFFFFF' }) => {
  const [contrastDetails, setContrastDetails] = useState(null);
  const { setErrorFeedback } = useFeedback();

  const cleanColor = (color) => {
    return color.replace('#', '');
  };

  const permaLink = useMemo(() => {
    return `https://webaim.org/resources/contrastchecker/?fcolor=${cleanColor(
      color,
    )}&bcolor=${cleanColor(comparisonColor)}`;
  }, [color, comparisonColor]);

  const getContrastDetails = useCallback(async () => {
    try {
      const response = await fetch(`${permaLink}&api`);
      const data = await response.json();
      setContrastDetails(data);
    } catch (error) {
      setErrorFeedback(getErrorMessage(error));
    }
  }, [permaLink, setErrorFeedback]);

  useEffect(() => {
    if (!color) return;
    getContrastDetails(color);
  }, [color, getContrastDetails, setErrorFeedback]);

  return (
    <Stack>
      <ContrastWrapper ratio={contrastDetails?.ratio}>
        <Stack className="contrast-example" backgroundColor={comparisonColor}>
          <Typography color={color} fontSize="1.25rem">
            Aa
          </Typography>
        </Stack>
        <Stack className="contrast-example" backgroundColor={color}>
          <Typography color={comparisonColor} fontSize="1.25rem">
            Aa
          </Typography>
        </Stack>
        <Stack direction="column" ml={1}>
          <Typography variant="h7" color="grey">
            Contrast Ratio:
          </Typography>
          <Typography variant="h5">{contrastDetails?.ratio || '--'}</Typography>
        </Stack>
      </ContrastWrapper>
      <Typography variant="caption" color="grey" mt={0.5}>
        Details about this ratio{' '}
        <Link href={permaLink} target="_blank" rel="noreferrer">
          here
        </Link>
        .
      </Typography>
    </Stack>
  );
};

ContrastChecker.propTypes = {
  color: PropTypes.string,
  comparisonColor: PropTypes?.string,
};
export default ContrastChecker;
