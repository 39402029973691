import styled from '@emotion/styled';

import MUICardContent from '@mui/material/CardContent';
import MUICardHeader from '@mui/material/CardHeader';

import { family, weight } from 'theme/fonts';

export const Info = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  '&>span:first-of-type': {
    fontWeight: weight.bold,
  },
}));

export const Dates = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '0.25rem',
  color: theme.palette.grey.main,
  textAlign: 'right',
}));

export const Table = styled('table')(({ theme }) => ({
  marginTop: '1rem',
  borderCollapse: 'collapse',

  '& td, & th': {
    border: '1px solid ' + theme.palette.grey.light,
    padding: '0.5rem',
  },
  '& tr td:first-of-type, & tr th:first-of-type': {
    border: 'none',
    textAlign: 'right',
    fontWeight: weight.bold,
  },
  '& tr:nth-of-type(2) th div': {
    gap: '0.25rem',
    fontWeight: weight.regular,

    '& span:first-of-type': {
      color: theme.palette.grey.main,
    },
  },
}));

export const Red = styled('td')(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
}));

export const Yellow = styled('td')(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
}));

export const Green = styled('td')(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

export const CardHeader = styled(MUICardHeader)(() => ({
  padding: 0,

  '.MuiTypography-root': {
    font: weight.bold + ' 1.25rem ' + family.openSans,
  },

  '.MuiCardHeader-action': {
    margin: 0,
  },
}));

export const CardContent = styled(MUICardContent)(() => ({
  padding: '1rem 0 0 0',

  '&:last-child': {
    paddingBottom: '0',
  },
}));
