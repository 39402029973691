import styled from '@emotion/styled';

import * as fonts from 'theme/fonts';

export const StyledColumnLabel = styled('div')(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey.extraLight,
  textAlign: 'center',
  fontWeight: fonts.weight.bold,
}));

export const StyledRowLabel = styled('div')(() => ({
  textAlign: 'right',
  marginBottom: '0.5rem',
}));
