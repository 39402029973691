import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import WidgetContainer from 'components/widgets/widget-container';
import WidgetTypeMenu from 'components/widgets/widget-type-menu';
import WoundSizeTimeSelector from 'components/widgets/WoundSizeTimeSelector';

import api from 'core/api';
import useRequest from 'hooks/useRequest';
import useWidgetModal, { modalTypeEnum } from 'hooks/useWidgetModal';
import { CARE_TRACK_PATHS } from 'navigation/paths';
import { formatISOString } from 'utils/date';
import { PeriodEnum } from 'utils/enums';

import NewPainMobility from '../NewPainMobility';

import PainMobilityGraph from './PainMobilityGraph';

const listViewMode = [PeriodEnum.WEEK, PeriodEnum.MONTH, PeriodEnum.YEAR];

const PainMobilityWidget = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { modal, handleAdd, handleClose } = useWidgetModal();

  const [viewMode, setViewMode] = React.useState(listViewMode[0]);
  const [filters, setFilters] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const {
    data: painMobilityData,
    doRequest: getPainAndMobility,
    isLoading,
  } = useRequest(api.getPainMobilityList);

  const handleRefresh = React.useCallback(() => {
    if (!filters) return;
    getPainAndMobility({
      patientId,
      ...filters,
      DateTo: formatISOString(filters.DateTo),
      DateFrom: formatISOString(filters.DateFrom),
    });
  }, [filters, patientId, getPainAndMobility]);

  React.useEffect(handleRefresh, [handleRefresh]);

  const renderModalContent = React.useMemo(() => {
    if (modal?.type === modalTypeEnum.ADD)
      return (
        <NewPainMobility
          handleCancel={handleClose}
          handleRefresh={handleRefresh}
        />
      );
    return null;
  }, [modal, handleClose, handleRefresh]);

  return (
    <>
      <WidgetContainer
        title="Pain, Mobility, Leg Swelling"
        onLogsClick={() => navigate(CARE_TRACK_PATHS.PAIN_MOBILITY)}
        onAddClick={handleAdd}
        modalContent={renderModalContent}
        onModalClose={handleClose}
      >
        <PainMobilityGraph
          painMobilityList={(!isLoading && painMobilityData?.data) || []}
          getPainMobilityData={setFilters}
          selectedDate={selectedDate}
          viewMode={viewMode}
          isLoading={isLoading}
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <WoundSizeTimeSelector
              name="pain-mobility-week-picker"
              viewMode={viewMode}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <WidgetTypeMenu
              options={listViewMode}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </Grid>
        </Grid>
      </WidgetContainer>
    </>
  );
};

export default PainMobilityWidget;
