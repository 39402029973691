import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/forms/input';

import {
  StyledColorPicker,
  StyledLabel,
  StyledInputWrapper,
  StyledTrigger,
} from './ColorPicker.styles';

const ColorPicker = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  disabled,
}) => {
  return (
    <StyledColorPicker>
      <StyledLabel htmlFor={name} disabled={disabled}>
        {label}
      </StyledLabel>
      <StyledInputWrapper>
        <StyledTrigger
          htmlFor={`${name}-picker`}
          value={value}
          disabled={disabled}
          aria-label="color-picker"
        />
        <input
          type="color"
          id={`${name}-picker`}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className="input-picker"
        ></input>
        <Input
          type="text"
          id={name}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </StyledInputWrapper>
    </StyledColorPicker>
  );
};

ColorPicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ColorPicker;
